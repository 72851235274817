import React from 'react';
import { MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';

const List = (props) => {
  return(
        <MDBTable scrollY maxHeight='100%'>
            <MDBTableHead>{props.head}</MDBTableHead>
            <MDBTableBody rows={ props.data } />
        </MDBTable>
  );
};

export default List;