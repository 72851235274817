import axios from 'axios'

const getOrderList = (callback) => {

    axios.get('/api/passOrderList')
    .then(function (res) {
        if (res.data === "unauthorized" || res.data === "error") {
            callback("error")
        } 
        else if (res.data === null) {
            callback("empty")
        }
        else {
            callback(res.data)
        }
    })
    .catch(function (error) {
        callback("error")
    });
}

export const orderPassOrderList = (numberAuto, timeStartOrder, description, company, callback) => {

    axios.post('/api/passOrderList/order', {
        "number_auto": numberAuto,
        "timeStartOrder": timeStartOrder,
        "description": description,
        "company": company
    })
    .then(function (res) {
        if (res.data === "unauthorized" || res.data === "error") {
            callback("error")
        } 
        else if (res.data === null) {
            callback("empty")
        }
        else {
            callback(res.data)
        }
    })
    .catch(function (error) {
        callback("error")
    });
}

export const deletePassOrderList = (numberAuto, company, callback) => {

    axios.post('/api/passOrderList/delete', {
        "number_auto": numberAuto,
        "company": company
    })
    .then(function (res) {
        if (res.data === "unauthorized" || res.data === "error") {
            callback("error")
        } 
        else if (res.data === null) {
            callback("empty")
        }
        else {
            callback(res.data)
        }
    })
    .catch(function (error) {
        callback("error")
    });
}

export default getOrderList