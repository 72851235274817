import React from 'react';
import { googleLogout } from '@react-oauth/google';
import "./Logout.css"

const clientId = '1071095414117-ltugqqi0kl6mgoqloguoqfriuer2rlke.apps.googleusercontent.com'

function Logout() {

    const onSuccess = () => {
        googleLogout();
        sessionStorage.clear()
        window.location.href = '/'
    }

    return(
        <div>
            <div onClick={onSuccess} className="buttonLogout"> Выйти из профиля </div>
        </div>
    )
}

export default Logout