const translateCharacter = {"А": "A", "В": "B", "Е": "E", "К": "K", "М": "M", "Н": "H", "О": "O", "Р": "P", "С": "C", "Т": "T", "У": "Y", "Х": "X"}

function normolizeNumberAuto(numberAuto)
{
    var newNumberAuto = ""
    for (let item of numberAuto.toUpperCase()) {
        if (/[А-Я]/.test(item)) {
            if (item in translateCharacter) {
                newNumberAuto += translateCharacter[item]
            } else {
                newNumberAuto += item
            }
        } else {
            newNumberAuto += item
        }
    }
    return newNumberAuto
}

const validateNumberAuto = (numberAuto) => {
    try {
        const regularExpressions = [
            /^(а|в|е|к|м|н|о|р|с|т|у|х|a|b|e|k|m|h|o|p|c|t|y|x){1}[0-9]{3}(а|в|е|к|м|н|о|р|с|т|у|х|a|b|e|k|m|h|o|p|c|t|y|x){2}[0-9]{2,3}$/gi, // Россия: Базовые номера
            /^(а|в|е|к|м|н|о|р|с|т|у|х|a|b|e|k|m|h|o|p|c|t|y|x){2}[0-9]{3}(а|в|е|к|м|н|о|р|с|т|у|х|a|b|e|k|m|h|o|p|c|t|y|x){1}[0-9]{2,3}$/gi, // Россия: Транзитные номера
            /^(а|в|е|к|м|н|о|р|с|т|у|х|a|b|e|k|m|h|o|p|c|t|y|x){2}[0-9]{5}$/gi, // Россия: Общественный транспорт
            /^(а|в|е|к|м|н|о|р|с|т|у|х|a|b|e|k|m|h|o|p|c|t|y|x){1}[0-9]{6}$/gi, // Россия: Номер полиции
            /^[0-9]{4}(а|в|е|к|м|н|о|р|с|т|у|х|a|b|e|k|m|h|o|p|c|t|y|x){2}[0-9]{2}$/gi, // Россия: Военные номера
            /^[0-9]{4}\/(а|в|е|к|м|н|о|р|с|т|у|х|a|b|e|k|m|h|o|p|c|t|y|x){2}[0-9]{2}$/gi, // Россия: номера мотоциклов и тракторов
            /^(а|в|е|к|м|н|о|р|с|т|у|х|a|b|e|k|m|h|o|p|c|t|y|x){1}[0-9]{3}(а|в|е|к|м|н|о|р|с|т|у|х|a|b|e|k|m|h|o|p|c|t|y|x){3}$/gi, // Казахстан: Частные номера стандарта 1993 года
            /^(а|в|е|к|м|н|о|р|с|т|у|х|a|b|e|k|m|h|o|p|c|t|y|x){1}[0-9]{3}(а|в|е|к|м|н|о|р|с|т|у|х|a|b|e|k|m|h|o|p|c|t|y|x){2}$/gi, // Казахстан: Номера организаций стандарта 1993 года
            /^[0-9]{3}(а|в|е|к|м|н|о|р|с|т|у|х|a|b|e|k|m|h|o|p|c|t|y|x){3}[0-9]{2}$/gi, // Казахстан: Частные номера стандарта 2012 года
            /^[0-9]{3}(а|в|е|к|м|н|о|р|с|т|у|х|a|b|e|k|m|h|o|p|c|t|y|x){2}[0-9]{2}$/gi, // Казахстан: Номера организаций стандарта 2012 года
        ]

        if(numberAuto.length > 5 && numberAuto.length < 10) {
            for (let item of regularExpressions) {
                if(numberAuto.match(item)) {
                    return { status: "success", numberAuto: normolizeNumberAuto(numberAuto) }
                } 
            }
            return  { status: "error", numberAuto: normolizeNumberAuto(numberAuto) }
        } else return { status: "error", numberAuto: normolizeNumberAuto(numberAuto) }
    } catch {
        return { status: "error", numberAuto: normolizeNumberAuto(numberAuto) }
    }
}

export default validateNumberAuto