import axios from 'axios'

const getAttendanceList = (beginDate, endDate, callback) => {

    console.log(beginDate, endDate)
    
    axios.post('/api/attendanceList', {
        "start_date": beginDate + " 00:00:00",
        "end_date": endDate + " 23:59:59"
    })
    .then(function (res) {
        if (res.data === "unauthorized" || res.data === "error") {
            callback("error")
        } 
        else if (res.data === null) {
            callback("empty")
        }
        else {
            console.log(res.data)
            callback(res.data)
        }
    })
    .catch(function (error) {
        callback("error")
    });
}

export default getAttendanceList