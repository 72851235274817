import axios from 'axios'

const getAccessList = (callback) => {

    axios.get('/api/accessList')
    .then(function (res) {
        if (res.data === "unauthorized" || res.data === "error") {
            callback("error")
        } 
        else if (res.data === null) {
            callback("empty")
        }
        else {
            callback(res.data)
        }
    })
    .catch(function (error) {
        callback("error")
    });
}

export const changeAccessList = (numberAuto, company, description, callback) => {

    
    axios.post('/api/accessList/change', {
        "number_auto": numberAuto,
        "company": company,
        "description": description
    })
    .then(function (res) {
        if (res.data === "unauthorized" || res.data === "error") {
            callback("error")
        } 
        else if (res.data === null) {
            callback("empty")
        }
        else {
            callback(res.data)
        }
    })
    .catch(function (error) {
        callback("error")
    });
}

export const deleteAccessList = (numberAuto, callback) => {

    
    axios.delete(`/api/accessList/delete/${numberAuto}`)
    .then(function (res) {
        if (res.data === "unauthorized" || res.data === "error") {
            callback("error")
        } 
        else if (res.data === null) {
            callback("empty")
        }
        else {
            callback(res.data)
        }
    })
    .catch(function (error) {
        callback("error")
    });
}

export default getAccessList