import React from 'react';
import { Modal } from 'react-bootstrap'

import "./Error.css"

function Error({ show, onHide }) {
    return(
        <div>
            <Modal
            show={show}
            onHide={onHide}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="authModal"
            >

            <Modal.Header closeButton>
                <h4 className="titleAuthModal">
                    {show ? show.title : <></>}
                </h4>
            </Modal.Header>
            <Modal.Body>
                <div className="textError">
                    {show ? show.text : <></>}
                </div>
            </Modal.Body>
            </Modal>
        </div>
    )
}


export default Error