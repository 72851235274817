import React, { useRef, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap'
import "./Login.css"
import axios from 'axios'
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';

function LoginButton({onHide, setError}) {
    const ref = useRef(null);
    const [width, setWidth] = useState(0);

    useEffect(() => {
        function handleWindowResize() {
            setWidth(ref.current.clientWidth);
        }
      
        setWidth(ref.current.clientWidth);
        window.addEventListener('resize', handleWindowResize);
      
        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);

    const responseGoogle = (response) => {
        if (response.credential){
            axios.get('/api/auth?token=' + response.credential)
            .then(function (res) {
                if (res.data.email_verified) {

                    const myUserEntity = {
                        "family": res.data.family_name,
                        "name": res.data.given_name,
                        "imageUrl": res.data.picture,
                        "roleUser": res.data.roleUser,
                        "companyUser": res.data.companyUser
                    };

                    sessionStorage.setItem('myUserEntity',JSON.stringify(myUserEntity));
                    
                    onHide()
                    window.location.reload();
                } else {
                    sessionStorage.clear()
                    setError({
                        "title": "Ошибка авторизации",
                        "text": "Вы должны иметь доступ к системе"
                    })
                }
            })
            .catch(function (error) {
                sessionStorage.clear()
                setError({
                    "title": "Ошибка авторизации",
                    "text": "Вы должны иметь доступ к системе"
                })
        });
        }
    }

    return(
        <div ref={ref} className="authButton">
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
                    <GoogleLogin
                        onSuccess={responseGoogle}
                        onError={() => {
                            console.log('Login Failed')
                        }}
                        width={width}
                    />
            </GoogleOAuthProvider>
        </div>
    )
}

function Login({show, onHide, setError}) {
    return(
        <div>
            <Modal
                show={show}
                onHide={onHide}
                size="sm"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="authModal"
                backdrop="static"
            >

            <Modal.Header>
                <h4 className="titleAuthModal">
                    Авторизация
                </h4>
            </Modal.Header>
            <Modal.Body>
                <LoginButton onHide={onHide} setError={setError}/>
            </Modal.Body>
            </Modal>
        </div>
    )
}


export default Login