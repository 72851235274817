import React from 'react'
import { MDBDataTable } from 'mdbreact';
import '@fortawesome/fontawesome-free/css/all.min.css'; 
import 'bootstrap-css-only/css/bootstrap.min.css'; 
import 'mdbreact/dist/css/mdb.css';

function TableParkingAttendance({data_table, type="ordinary"}) {

    const widerData = {
        columns: [
          ...data_table.columns.map((col) => {
            col.width = 100;
            return col;
          }),
        ],
        rows: [...data_table.rows],
      };
    return (
      <>
        {(type === "ordinary") ? (
            <MDBDataTable data={widerData} paging={false} responsive/>
        ) : (
            <div></div>
        )
        }
      </>
    );
}

export default TableParkingAttendance;