import React from 'react';
import { MDBBtn, MDBIcon} from 'mdbreact';
import { Form } from 'react-bootstrap'

import "./AccessListPage.css"

import List from "../../components/list/List"
import getAccessList, { changeAccessList, deleteAccessList } from "../../services/requestsAccessList"
import validateNumberAuto from '../../services/validateNumberAuto';

import SaveChanges from '../../components/modals/modalSaveChanges/SaveChanges';

export default class AccessListPage extends React.Component {

    constructor() {
        super();
        this.state = {
            data_table: [],
            data_head: <tr></tr>,
            saveChanges: false
        }
        this.deleteNumberAuto = this.deleteNumberAuto.bind(this);
        this.change = this.change.bind(this);  
        this.createDataTable = this.createDataTable.bind(this);  
        this.saveChanges = this.saveChanges.bind(this);  
    }

    saveChanges(e) {
        console.log("Сохранить", e)
        if (e.title === "invalid number format") {

            let clearNumberAuto = document.getElementsByClassName('number_auto_input')[0].value.replace(/\s/g, '')
            console.log("Очищенный номер авто", clearNumberAuto)
            
            changeAccessList(clearNumberAuto, document.getElementsByClassName('company_input')[0].value, document.getElementsByClassName('description_input')[0].value, (data) => {
                if (data === "empty") {
                    return
                }
                else if (data === "exists in orderList") {
                    this.props.setError("На данный автомобиль уже заказан временный пропуск")
                    return
                }
                else if (data === "error") {
                    this.props.setError("Что то пошло не так")
                    return
                }

                const dictionary = this.createDataTable(data)
                this.setState({ data_table: dictionary})

                document.getElementsByClassName('number_auto_input')[0].value = ""
                document.getElementsByClassName('company_input')[0].value = ""
                document.getElementsByClassName('description_input')[0].value = ""
            })
        }
        else if (e.title === "delete_numberAuto") {
            deleteAccessList(e.numberAuto, (data) => {
                console.log("data:", data) 
                if (data === "empty") {
                    console.log("Нет данных")
                    this.setState({data_table: [], data_head: <tr></tr>})
                    return
                }
                else if (data === "error") {
                    this.props.setError({
                        "title": "Ошибка",
                        "text": `Не удалось удалить постоянный пропуск`
                    })
                    return
                }
    
                const dictionary = this.createDataTable(data)
                this.setState({ data_table: dictionary})
            })
        }
        this.setState({saveChanges: false})
    }

    createDataTable(data) {
        var dictionary = []
        for (const [, value] of Object.entries(data)) {
            dictionary.push({
            'numberauto': value["numberauto"],
            'company': value["company"],
            'description': value["description"],
            'indicator': <MDBBtn tag='a' size='sm' color="warning" onClick={() => this.deleteNumberAuto(value["numberauto"])} floating="true" ><MDBIcon fas icon="trash" /></MDBBtn>
            });
        }

        return dictionary
    }

    change() {
        if (document.getElementsByClassName('number_auto_input')[0].value === "") {
            this.props.setError({
                "title": "Ошибка",
                "text": "Введите номер авто"
            })
            return
        }

        if (document.getElementsByClassName('description_input')[0].value === "") {
            this.props.setError({
                "title": "Ошибка",
                "text": "Введите описание для постоянного пропуска"
            })
            return
        }

        let clearNumberAuto = document.getElementsByClassName('number_auto_input')[0].value.replace(/\s/g, '')
        console.log("Очищенный номер авто", clearNumberAuto)

        let result = validateNumberAuto(clearNumberAuto) 
        if (result.status === "error") {
            console.log("Номер неизвестного формата")

            this.setState({saveChanges: {
                "title": "Номер неизвестного формата", 
                "text": `Вы уверены, что номер "${clearNumberAuto}" введен верно?`,
                "cancel": "Отмена",
                "save": "Сохранить",
                "function": {
                    "title": "invalid number format"
                }
            }})
            
            return
        }

        changeAccessList(result.numberAuto, document.getElementsByClassName('company_input')[0].value, document.getElementsByClassName('description_input')[0].value, (data) => {

            if (data === "empty") {
                return
            }

            else if (data === "exists in orderList") {
                this.props.setError({
                    "title": "Ошибка",
                    "text": `На автомобиль с номером ${result.numberAuto} уже заказан временный пропуск`
                })
                return
            }

            else if (data === "error") {
                this.props.setError({
                    "title": "Ошибка",
                    "text": "Не удалось добавить данный автомобиль в постоянный список доступа"
                })
                return
            }

            const dictionary = this.createDataTable(data)
            this.setState({ data_table: dictionary})

            document.getElementsByClassName('number_auto_input')[0].value = ""
            document.getElementsByClassName('company_input')[0].value = ""
            document.getElementsByClassName('description_input')[0].value = ""
        })
    }

    componentDidMount() {        
        getAccessList((data) => {
            console.log("data:", data) 
            if (data === "empty") {
                console.log("Нет данных")
                this.setState({data_table: [], data_head: <tr></tr>})
                return
            }
            else if (data === "error") {
                return
            }
            
            const dictionary = this.createDataTable(data)
            this.setState({ data_table: dictionary, data_head: <tr><th>Номер авто</th><th>Компания</th><th>Примечание</th><th></th></tr>})
        })
    }

    deleteNumberAuto(numberAuto) {
        this.setState({saveChanges: {
            "title": "Удаление постоянного пропуска", 
            "text": `Удалить постоянный пропуск для автомобиля с номером - ${numberAuto}?`,
            "cancel": "Отмена",
            "save": "Удалить",
            "function": {
                "title": "delete_numberAuto",
                "numberAuto": numberAuto
            }
        }})     
    }
    
    render() {
        return(
            <div className="AccessListPage">
                {sessionStorage.getItem('myUserEntity') === null ? (
                    <div>  </div>
                ) : (
                    <>
                        <SaveChanges 
                            show={this.state.saveChanges} 
                            setShow={() => this.setState({saveChanges: false})}
                            saveChange={(e) => this.saveChanges(e)}
                        />
                        <div className="viewParentTop">
                            <div className="viewTop">
                                <div className="addressParking"> Москва, Дмитрия Ульянова 42с1 </div>
                                <div className="changeAccessListView">
                                    <div className="TextFieldNumberAuto"> 
                                        <Form.Control placeholder="Введите номер авто *" className="number_auto_input"/> 
                                    </div>
                                    <div className="TextFieldCompany"> 
                                        <Form.Control placeholder="Введите компанию" className="company_input"/> 
                                    </div>
                                    <div className="TextFieldDescription"> 
                                        <Form.Control placeholder="Введите примечание *" className="description_input"/> 
                                    </div>
                                    <div className="ButtonChangeView"> 
                                        <button className="ButtonChange" onClick={() => this.change()}> Добавить/изменить </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        { (Object.keys(this.state.data_table).length > 0) ? (
                            <div className="List">
                                <List 
                                    data={this.state.data_table} 
                                    head={this.state.data_head}
                                />
                            </div>
                        ) : <div className='noData'>
                                Нет данных
                            </div>
                        }
                    </>
                )}
            </div>
        )
    }

}