import React from "react";
import { Form } from 'react-bootstrap'

import "./AdminPanel.css"
import { MDBBtn, MDBIcon} from 'mdbreact';

import List from "../../components/list/List"
import DropdownList from "../../components/dropdownlist/DropdownList"

import getAdminList, { registerUser, deleteUserList } from "../../services/requestsAdminPanel"

import SaveChanges from "../../components/modals/modalSaveChanges/SaveChanges";

export default class AttendanceParkingPage extends React.Component {

    constructor() {
        super();
        this.state = {
            data_table: [],
            data_head: <tr></tr>,
            selectedType: "Выберите роль *",
            options: ["Арендатор", "Администратор"],
            saveChanges: false
        }
        this.setSelectedType = this.setSelectedType.bind(this);
        this.addUser = this.addUser.bind(this);
        this.saveChanges = this.saveChanges.bind(this);  
    }

    saveChanges(e) {
        console.log("Сохранить", e)
        if (e.title === "delete_user") {
            deleteUserList(e.email, (data) => {
                if (data === "empty") {
                    console.log("Нет данных")
                    this.setState({data_table: [], data_head: <tr></tr>})
                    return
                }
                else if (data === "error") {
                    this.props.setError({
                        "title": "Ошибка",
                        "text": `Не удалось удалить пользователя`
                    })
                    return
                }

                else if (data === "attempt to delete yourself") {
                    this.props.setError({
                        "title": "Ошибка",
                        "text": `Нельзя удалить свой аккаунт`
                    })
                    return
                }
    
                for (const [key, value] of Object.entries(data)) {
                    data[key]['indicator'] = <MDBBtn tag='a' size='sm' color="warning" onClick={() => this.deleteUser(value["emailuser"])} floating="true" ><MDBIcon fas icon="trash" /></MDBBtn>
                }
    
                this.setState({ data_table: data, data_head: <tr><th>Почта</th><th>Роль</th><th>Компания</th></tr>})
            })
        }
        this.setState({saveChanges: false})
    }

    addUser() {
        if ((this.state.selectedType === "Арендатор" && document.getElementsByClassName('email_input')[0].value !== "" && document.getElementsByClassName('company_input')[0].value !== "") ||
        (this.state.selectedType === "Администратор" && document.getElementsByClassName('email_input')[0].value !== "")) {

            var email = ""
            if (this.state.selectedType === "Администратор") {
                email = 'admin'
            } else {
                email = 'tenant'
            }

            registerUser(document.getElementsByClassName('email_input')[0].value, email, document.getElementsByClassName('company_input')[0].value, (data) => {
                if (data === "empty") {
                    console.log("Нет данных")
                    this.setState({data_table: [], data_head: <tr></tr>})
                    return
                }
                else if (data === "error") {
                    this.props.setError({
                        "title": "Ошибка",
                        "text": `Не удалось добавить нового пользователя`
                    })
                    return
                }

                for (const [key, value] of Object.entries(data)) {
                    data[key]['indicator'] = <MDBBtn tag='a' size='sm' color="warning" onClick={() => this.deleteUser(value["emailuser"])} floating="true" ><MDBIcon fas icon="trash" /></MDBBtn>
                }

                this.setState({ data_table: data, data_head: <tr><th>Почта</th><th>Роль</th><th>Компания</th></tr>})
                document.getElementsByClassName('email_input')[0].value = ""
                document.getElementsByClassName('company_input')[0].value = ""
                this.setState({selectedType: "Выберите роль *"})
            }) 
        }
        else {
            if (this.state.selectedType === "Арендатор") {
                this.props.setError({
                    "title": "Ошибка",
                    "text": "Введите почту и название компании арендатора"
                })
            } 
            else if (this.state.selectedType === "Администратор") {
                this.props.setError({
                    "title": "Ошибка",
                    "text": "Введите почту администратора"
                })
            } else {
                this.props.setError({
                    "title": "Ошибка",
                    "text": "Выберите роль для пользователя"
                })
            }
        }
    }

    deleteUser(email) {
        console.log(email)
        
        this.setState({saveChanges: {
            "title": "Удаление пользователя", 
            "text": `Удалить пользователя ${email}?`,
            "cancel": "Отмена",
            "save": "Удалить",
            "function": {
                "title": "delete_user",
                "email": email
            }
        }}) 
    }

    componentDidMount() {
        //var dict = this.sorted_list(this.state.data_table)
        
        getAdminList((data) => {
            console.log("data:", data) 
            if (data === "empty") {
                console.log("Нет данных")
                this.setState({data_table: [], data_head: <tr></tr>})
                return
            }
            else if (data === "error") {
                return
            }

            for (const [key, value] of Object.entries(data)) {
                data[key]['indicator'] = <MDBBtn tag='a' size='sm' color="warning" onClick={() => this.deleteUser(value["emailuser"])} floating="true" ><MDBIcon fas icon="trash" /></MDBBtn>
            }

            this.setState({ data_table: data, data_head: <tr><th>Почта</th><th>Роль</th><th>Компания</th></tr>})
        })
    }

    setSelectedType = (e) => {
        if (e === "") {
            this.setState({selectedType: "Выберите роль *"})
        } else {
            console.log(e)
            this.setState({selectedType: e})
        }
    }

    render() {
        return (
            <>
                <SaveChanges 
                                show={this.state.saveChanges} 
                                setShow={() => this.setState({saveChanges: false})}
                                saveChange={(e) => this.saveChanges(e)}
                            />
                <div className="AdminPanel">
                    <div className="viewParentTop">
                        <div className="viewTop">
                            <div className="addressParking"> Москва, Дмитрия Ульянова 42с1 </div>
                            <div className="changeAccessListView">
                                <div className="TextFieldRole">
                                    <DropdownList selectedType={this.state.selectedType} setSelectedType={this.setSelectedType} options={this.state.options}/>
                                </div>
                                <div className="TextFieldEmail"> 
                                    <Form.Control placeholder="Введите почту *" className="email_input"/> 
                                </div>
                                <div className="TextFieldCompany"> 
                                    <Form.Control placeholder="Введите компанию" className="company_input"/> 
                                </div>
                                <div className="ButtonChangeView"> 
                                    <button className="ButtonChange" onClick={() => this.addUser()}> Добавить/изменить </button>
                                </div>
                            </div>
                        </div>
                    </div>
                        { (Object.keys(this.state.data_table).length > 0) ? (
                            <div className="List">
                                <List 
                                    data={this.state.data_table} 
                                    head={this.state.data_head}
                                />
                            </div>
                        ) : (
                            <div className="noData">
                                Нет данных
                            </div>
                        )}
                </div>
            </>
        )
    }
}