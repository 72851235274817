import React from 'react';
import { Modal, Button } from 'react-bootstrap'

import './SaveChanges.css'

function SaveChanges({show, setShow, saveChange}) {

    return(
        <div>
            <Modal show={show} onHide={() => setShow()} centered className='modalSaveChanges'>
                <Modal.Header>
                    <Modal.Title> {show ? show.title : <></>} </Modal.Title>
                </Modal.Header>
                <Modal.Body> {show ? show.text : <></>} </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={() => setShow()} style={{textTransform: 'capitalize'}}>
                        {show ? show.cancel : <></>}
                    </Button>
                    <Button variant="warning" onClick={() => saveChange(show ? show.function : {})} style={{textTransform: 'capitalize'}}>
                        {show ? show.save : <></>}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}


export default SaveChanges