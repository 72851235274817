import React from 'react';
import TextField from '@material-ui/core/TextField';

import "./DateTimePicker.css"
import moment from 'moment';

export default function DateTimePicker({valueRef, defaultTime, maxTime}) {
  return (
      <TextField
          id="datetime-local"
          type="datetime-local"
          className='dateTimePicker'
          label="Время начала действия"
          defaultValue={defaultTime}
          inputRef={valueRef}
          InputLabelProps={{
              shrink: true,
          }}
          InputProps={{ 
              disableUnderline: true,
          }}
          inputProps={{min: defaultTime, max: maxTime}}
          style={{fontAlign: "left"}}
      />
  );
}