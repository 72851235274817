import React from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

import './DataPicker.css'

function DatePicker({beginDate, endDate, changeData}) {
         return (
             <>
                <DateRangePicker 
                    initialSettings={{ 
                        startDate: beginDate, 
                        endDate: endDate, 
                        locale: {
                            format: 'MMMM D, YYYY', 
                            applyLabel: 'Сохранить', 
                            cancelLabel: "Назад",
                            daysOfWeek: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"], 
                            monthNames: ["Январь", "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август","Сентябрь","Октябрь","Ноябрь", "Декабрь"]
                        }
                    }} onCallback={changeData}>
                    <input type="text" className="form-control" id="id_datapicker"/>
                </DateRangePicker>
            </>
        )
}

export default DatePicker
