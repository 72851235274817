import React, { useState } from 'react';
import{ BrowserRouter as Router, Routes, Route, } from "react-router-dom"

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import AccessListPage from "./views/accessList/AccessListPage"
import AttendanceParkingPage from "./views/attendanceParking/AttendanceParkingPage"
import PassOrderPage from "./views/passOrder/PassOrderPage"
import AdminPanel from "./views/adminPanel/AdminPanel"

import NavigationBar from "./components/navbar/NavBar"
import Login from "./components/login/Login"

import Error from "./components/modals/modalError/Error"

function App() {
  const [show, setShow] = useState(true);
  const [error, setError] = useState(false);

  var roleUser = ""
  try {
    roleUser = JSON.parse(sessionStorage.getItem('myUserEntity'))["roleUser"]
  } catch(err) {
    roleUser = ""
  }
  
  if (window.location.href.split("/")[3] === "" && sessionStorage.getItem('myUserEntity') !== null)  {
      if (roleUser === 'admin') {
        window.location.href += "attendance"
      } else {
        window.location.href += "pass"
      }
      return
  }

  return (
    <div className="App">
      <Error 
          show={error} 
          onHide={setError}
      />
      { sessionStorage.getItem('myUserEntity') === null ?
        <Login  
            show={show}
            onHide={setShow}
            setError={setError}
        /> : <div></div>
      }
      <Router>
        <NavigationBar />
          { roleUser === 'admin' ?
          <div>
          <Routes>
            <Route path="/list" element={<AccessListPage setError={setError}/>} />
            <Route path="/attendance" element={<AttendanceParkingPage setError={setError}/>} />
            <Route path="/pass" element={<PassOrderPage setError={setError}/>} />
            <Route path="/adminpanel" element={<AdminPanel setError={setError}/>} />
            <Route path="/" element={<AttendanceParkingPage setError={setError}/>} />
          </Routes>
          </div> : <div>
          <Routes>
            <Route path="/pass" element={<PassOrderPage setError={setError}/>} />
            <Route path="/" element={<PassOrderPage setError={setError}/>} />
          </Routes>
          </div>
          }
      </Router>
    </div>
  );
}

export default App;