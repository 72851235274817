import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';

import "./AttendanceParkingPage.css"

import Table from "../../components/table/Table"
import DataPicker from "../../components/datapicker/DataPicker"

import moment from 'moment';
import ruLocale from 'moment/locale/ru'

import XLSX from "xlsx-js-style";

import getAttendanceList from "../../services/getAttendanceList"

moment.locale('ru', [ruLocale])

export default class AttendanceParkingPage extends React.Component {

    constructor() {
        super();
        this.state = {
            data_table: {},
            data: [],
            time_interval: [moment().subtract(1, "days").format("YYYY-MM-DD") , moment().format("YYYY-MM-DD")]
         }
        this.changeData = this.changeData.bind(this); 
        this.load_table = this.load_table.bind(this); 
        this.tableStyling = this.tableStyling.bind(this); 
    }

    sorted_list(dict) {
        dict.sort(function(a, b) {
            var keyA = new Date(a.time_arrive),
              keyB = new Date(b.time_arrive);

            if (keyA > keyB) return -1;
            if (keyA < keyB) return 1;
            return 0;
          });
          return dict
    }

    changeData(start, end) {
        let begin_format = start.format('YYYY-MM-DD')
        let end_format = end.format('YYYY-MM-DD')

        console.log(begin_format, end_format)
        this.setState({time_interval: [begin_format, end_format]})
    }

    getNumberOfDays(start, end) {
        const date1 = new Date(start);
        const date2 = new Date(end);
    
        const diffInTime = date2.getTime() - date1.getTime();
        const diffInDays = Math.round(diffInTime / (1000 * 60 * 60 * 24));
    
        return diffInDays;
    }

    set_data_to_table(data) {
        this.setState({data_table: {
            columns: [
                {
                    label: 'Тип пропуска',
                    field: 'typepass',
                    sort: 'asc'
                },
                {
                label: 'Номер авто',
                field: 'numberauto',
                sort: 'asc'
            },
            {
                label: 'Компания',
                field: 'company',
                sort: 'asc'
            },
            {
                label: 'Примечание',
                field: 'description',
                sort: 'asc'
            },
            {
                label: 'Время заезда',
                field: 'timearrive',
                sort: 'asc'
            }
            ,
            {
                label: 'Время выезда',
                field: 'timeexit',
                sort: 'asc'
            },
            {
                label: 'Время заказа',
                field: 'timeorder',
                sort: 'asc'
            }
            ],
            rows: data
        }, data: data})
    }   

    load_table(beginDate, endDate) {
        if (this.getNumberOfDays(beginDate, endDate) > 90){
            this.props.setError({
                "title": "Ошибка",
                "text": `Максимальный диапазон дат для построения отчета 90 дней`
            })
            return
        }
        getAttendanceList(beginDate, endDate, (data) => {
            if (data === "empty") {
                this.setState({data_table: {}, data: []})
                return
            }
            else if (data === "error") {
                this.props.setError({
                    "title": "Ошибка",
                    "text": `Не удалось загрузить данные`
                })
                return
            }
            this.set_data_to_table(data)
        }) 

    }

    tableStyling(ws) {
        for (const key in ws) {
            if(key.replace(/[^0-9]/ig, '')) {
                 if (ws[key].v === null) {
                     ws[key].t = "s"
                     ws[key].v = ""
                 }
                 if (key.replace(/[^0-9]/ig, '') === '1') {
                     ws[key].s = {
                         fill: { fgColor: { rgb: "cef0c9" } },
                         alignment: { horizontal: 'center', vertical: 'center', wrapText: true },
                         font: { sz: 14 },
                         border: {
                             bottom: {
                                 style: 'thin',
                                 color: 'black'
                             },
                             top: {
                                 style: 'thin',
                                 color: 'black'
                             },
                             left: {
                                 style: 'thin',
                                 color: 'black'
                             },
                             right: {
                                 style: 'thin',
                                 color: 'black'
                             }
                         }
                     }
                 }
                 else {
                     ws[key].s = {
                         alignment: { horizontal: 'center', vertical: 'center', wrapText: true },
                         font: { sz: 13 },
                         border: {
                             bottom: {
                                 style: 'thin',
                                 color: 'black'
                             },
                             top: {
                                 style: 'thin',
                                 color: 'black'
                             },
                             left: {
                                 style: 'thin',
                                 color: 'black'
                             },
                             right: {
                                 style: 'thin',
                                 color: 'black'
                             }
                         }
                     }
                 }
            }
         }
         return ws
    }

    handleExport = () => {
        const beginDate = this.state.time_interval[0]
        const endDate = this.state.time_interval[1]

        if (this.getNumberOfDays(beginDate, endDate) > 90){
            this.props.setError({
                "title": "Ошибка",
                "text": `Максимальный диапазон дат для построения отчета 90 дней`
            })
            return
        }
        getAttendanceList(beginDate, endDate, (data) => {
            if (data === "empty") {
                this.setState({data_table: {}, data: []})
                return
            }
            else if (data === "error") {
                this.props.setError({
                    "title": "Ошибка",
                    "text": `Не удалось загрузить данные`
                })
                return
            }
            this.set_data_to_table(data)

            const wb = XLSX.utils.book_new();

            var headings = [['Тип пропуска', 'Номер авто', 'Компания', 'Примечание', 'Время заезда', 'Время выезда', 'Время заказа пропуска']];

            var ws1 = XLSX.utils.json_to_sheet([]);
            ws1['!cols'] = [{wch:20}, {wch:20}, {wch:20}, {wch:40}, {wch:30}, {wch:30}, {wch:30}];

            XLSX.utils.sheet_add_aoa(ws1, headings, { origin: "A1" });
            XLSX.utils.sheet_add_json(ws1, data, { origin: 'A2', skipHeader: true });

            ws1 = this.tableStyling(ws1)
            XLSX.utils.book_append_sheet(wb, ws1, 'Общий отчет');

            var temporary = []
            for( let [, value] of Object.entries(data)) {
                if (value["typepass"] === 'Временный') {
                    temporary.push(value)
                }
            }
            headings = [['Тип пропуска', 'Номер авто', 'Компания', 'Примечание', 'Время заезда', 'Время выезда', 'Время заказа пропуска']];
            
            var ws2 = XLSX.utils.json_to_sheet([]);
            ws2['!cols'] = [{wch:20}, {wch:20}, {wch:30}, {wch:40}, {wch:30}, {wch:30}, {wch:30}];

            XLSX.utils.sheet_add_aoa(ws2, headings, { origin: "A1" });
            XLSX.utils.sheet_add_json(ws2, temporary, { origin: 'A2', skipHeader: true });

            ws2 = this.tableStyling(ws2)

            XLSX.utils.book_append_sheet(wb, ws2, 'Отчет по временным пропускам');

            var offenders = []

            for( let [, value] of Object.entries(data)) {
                if (moment(value["timeexit"]).isAfter(moment(moment(value["timearrive"]).add(75, 'minutes').format('YYYY-MM-DD HH:mm:ss'))) && value["typepass"] === 'Временный') {
                    var new_value = {...value}
                    var time = moment(new_value["timeexit"]).diff(moment(new_value["timearrive"]), 'minutes')
                    if (time % 60 === 0) {
                        new_value["time_parking"] = Math.floor(time/60) + ":00"
                    } else {
                        new_value["time_parking"] = Math.floor(time/60) + ":" + String(time % 60).padStart(2, '0')
                    }
                    new_value["standstill_time"] = Math.ceil((time - 75)/60);
                    offenders.push(new_value)
                }
            }

            headings = [['Тип пропуска', 'Номер авто', 'Компания', 'Примечание', 'Время заезда', 'Время выезда', 'Время заказа пропуска', 'Время паркинга', 'Время перестоя']];

            var ws3 = XLSX.utils.json_to_sheet([]);
            ws3['!cols'] = [{wch:20}, {wch:20}, {wch:30}, {wch:40}, {wch:30}, {wch:30}, {wch:20}, {wch:20}, {wch:20}];

            XLSX.utils.sheet_add_aoa(ws3, headings, { origin: "A1" });
            XLSX.utils.sheet_add_json(ws3, offenders, { origin: 'A2', skipHeader: true });

            ws3 = this.tableStyling(ws3)
            XLSX.utils.book_append_sheet(wb, ws3, 'Отчет по нарушениям');

            XLSX.writeFile(wb, `Parking Report (from ${this.state.time_interval[0]} to ${this.state.time_interval[1]}).xlsx`);

        })
    }

    componentDidMount() {
        this.load_table(this.state.time_interval[0], this.state.time_interval[1])
    }
    
    render() {
        return(
            <div className="AttendanceParkingPage">
            {sessionStorage.getItem('myUserEntity') === null ? (
                <div>  </div>
            ) : (
                <>
                <div className="parentViewTop">
                    <div className="viewTop">
                        <div className="addressParking"> Москва, Дмитрия Ульянова 42с1 </div>
                        <div className="datapicker"><DataPicker beginDate={moment().subtract(1, "days").format("MMMM D, YYYY")} endDate={moment().format("MMMM D, YYYY")} changeData={this.changeData}/> </div>
                        <div className="buttons">
                            <div className="viewButtonFind"><button className="buttonFind" onClick={() => this.load_table(this.state.time_interval[0], this.state.time_interval[1])}> Поиск </button></div>
                            <div className="viewButtonExport"><button className="buttonExport" onClick={() => this.handleExport()}> Экспорт в Excel </button></div>
                        </div>
                    </div>
                </div>
                { (Object.keys(this.state.data_table).length > 0) ? (
                    <>
                        <div className="report"> Общий отчет </div>
                        <div className="tableData"><Table data_table={this.state.data_table} /> </div>
                    </>
                ) : (
                    <div className='noData'>
                        Нет данных
                    </div>
                )}   
                </>
            )}
            </div>
        )
    }

}