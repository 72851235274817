import React from 'react';

import "./PassOrderPage.css"

import { MDBBtn, MDBIcon} from 'mdbreact';
import { Form } from "react-bootstrap"
import List from "../../components/list/List"
import DateTimePicker from "../../components/datatimepicker/DateTimePicker"

import getOrderList, { orderPassOrderList, deletePassOrderList } from "../../services/requestsOrderList"
import validateNumberAuto from '../../services/validateNumberAuto';

import SaveChanges from '../../components/modals/modalSaveChanges/SaveChanges';

import moment  from 'moment';

export default class PassOrderPage extends React.Component {

    constructor() {
        super();
       
        this.state = {
            data_table: [],
            data_head: <tr></tr>,
            defaultTime: moment().format('YYYY-MM-DDTHH:mm'),
            maxTime: moment().add(24, 'hours').format('YYYY-MM-DD HH:mm:ss'),
            saveChanges: false
        }
        this.deleteTerm = this.deleteTerm.bind(this); 
        this.order = this.order.bind(this); 
        this.orderPass = this.orderPass.bind(this);  
        this.saveChanges = this.saveChanges.bind(this);

        this.valueRef = React.createRef("");
    }

    saveChanges(e) {
        if (e.title === "invalid number format") {
            this.orderPass(e)
        }
        else if (e.title === "delete_numberAuto") {
            deletePassOrderList(e.numberAuto, e.company, (data) => {
                console.log("data:", data) 
                if (data === "empty") {
                    console.log("Нет данных")
                    this.setState({data_table: [], data_head: <tr></tr>})
                    return
                }
                else if (data === "error") {
                    this.props.setError({
                        "title": "Ошибка",
                        "text": `Не удалось удалить временный пропуск`
                    })
                    return
                }
    
                for (const [key, value] of Object.entries(data)) {
                    data[key]['indicator'] = <MDBBtn tag='a' size='sm' color="warning" onClick={() => this.deleteTerm(value["numberauto"], value["company"])} floating="true" ><MDBIcon fas icon="trash" /></MDBBtn>
                }

                if (JSON.parse(sessionStorage.getItem('myUserEntity'))["roleUser"] === 'admin') {
                    this.setState({ data_table: data, data_head: <tr><th>Номер авто</th><th>Компания</th><th>Примечание</th><th>Время заказа</th><th>Время начала действия</th><th>Время окончания действия</th></tr>})
                } else {
                    this.setState({ data_table: data, data_head: <tr><th>Номер авто</th><th>Примечание</th><th>Время заказа</th><th>Время начала действия</th><th>Время окончания действия</th></tr>})
                }
            })
        }
        this.setState({saveChanges: false})
    }

    componentDidMount() {
        if (document.getElementById("ad")) {
            document.getElementById("ad").innerHTML = 'Внимание! Пропуск на временный въезд только для погрузочных и разгрузочных работ.\nОграничение пребывания на паркинге 1 чаc.'
        }        

        getOrderList((data) => {
            console.log("data:", data) 
            if (data === "empty") {
                console.log("Нет данных")
                this.setState({data_table: [], data_head: <tr></tr>})
                return
            }
            else if (data === "error") {
                return
            }

            for (const [key, value] of Object.entries(data)) {
                data[key]['indicator'] = <MDBBtn tag='a' size='sm' color="warning" onClick={() => this.deleteTerm(value["numberauto"], value["company"])} floating="true" ><MDBIcon fas icon="trash" /></MDBBtn>
            }

            if (JSON.parse(sessionStorage.getItem('myUserEntity'))["roleUser"] === 'admin') {
                this.setState({ data_table: data, data_head: <tr><th>Номер авто</th><th>Компания</th><th>Примечание</th><th>Время заказа</th><th>Время начала действия</th><th>Время окончания действия</th></tr>})
            } else {
                this.setState({ data_table: data, data_head: <tr><th>Номер авто</th><th>Примечание</th><th>Время заказа</th><th>Время начала действия</th><th>Время окончания действия</th></tr>})
            }
        })
    }

    deleteTerm(numberAuto, company) {
        console.log("DELETE", numberAuto, company)
        this.setState({saveChanges: {
            "title": "Удаление временного пропуска", 
            "text": `Удалить временный пропуск для автомобиля с номером - ${numberAuto}?`,
            "cancel": "Отмена",
            "save": "Удалить",
            "function": {
                "title": "delete_numberAuto",
                "numberAuto": numberAuto,
                "company": company
            }
        }})
    }

    sorted_list(dict) {
        dict.sort(function(a, b) {
            var keyA = new Date(a.time_pass_completion),
              keyB = new Date(b.time_pass_completion);

            if (keyA < keyB) return -1;
            if (keyA > keyB) return 1;
            return 0;
          });
          return dict
    }

    orderPass(result) {

        var company = null
        if(document.querySelector(".company_input")) {
            company = document.getElementsByClassName('company_input')[0].value
        }

        var timeStartOrder = "now"
        if (this.valueRef.current === null) {
            timeStartOrder = "now"
        } 
        else {
            if (moment(this.valueRef.current.value.replace('T', ' ') + ":00").isAfter(moment().add(1, 'minutes'))) {
                timeStartOrder = this.valueRef.current.value.replace('T', ' ') + ":00"
            } 
            else {
                timeStartOrder = "now"
            }

            this.valueRef.current.value = moment().format('YYYY-MM-DDTHH:mm')
        }
        
        orderPassOrderList(result.numberAuto, timeStartOrder, document.getElementsByClassName('description_input')[0].value, company, (data) => {
            if (data === "empty") {
                console.log("Нет данных")
                this.setState({data_table: [], data_head: <tr></tr>})
                return
            }
            
            else if (data === "error") {
                this.props.setError({
                    "title": "Ошибка",
                    "text": `На автомобиль с номером ${result.numberAuto} уже заказан постоянный пропуск`
                })

                return
            }

            for (const [key, value] of Object.entries(data)) {
                data[key]['indicator'] = <MDBBtn tag='a' size='sm' color="warning" onClick={() => this.deleteTerm(value["numberauto"])} floating="true" ><MDBIcon fas icon="trash" /></MDBBtn>
            }

            if (JSON.parse(sessionStorage.getItem('myUserEntity'))["roleUser"] === 'admin') {
                this.setState({ data_table: data, data_head: <tr><th>Номер авто</th><th>Компания</th><th>Примечание</th><th>Время заказа</th><th>Время начала действия</th><th>Время окончания действия</th></tr>})
            } else {
                this.setState({ data_table: data, data_head: <tr><th>Номер авто</th><th>Примечание</th><th>Время заказа</th><th>Время начала действия</th><th>Время окончания действия</th></tr>})
            }

            document.getElementsByClassName('number_auto_input')[0].value = ""
            document.getElementsByClassName('description_input')[0].value = ""
            if(document.querySelector(".company_input")) {
                document.getElementsByClassName('company_input')[0].value = ""
            }
        })
    }

    order() {
        console.log("Заказать пропуск")
        if (document.getElementsByClassName('number_auto_input')[0].value === "") {
            this.props.setError({
                "title": "Ошибка",
                "text": "Введите номер авто"
            })
            return
        }

        if (document.getElementsByClassName('description_input')[0].value === "") {
            this.props.setError({
                "title": "Ошибка",
                "text": "Введите примечание для временного пропуска"
            })
            return
        }

        let clearNumberAuto = document.getElementsByClassName('number_auto_input')[0].value.replace(/\s/g, '')
        console.log("Очищенный номер авто", clearNumberAuto)

        let result = validateNumberAuto(clearNumberAuto) 
        if (result.status === "error") {
            console.log("Номер неизвестного формата", result.numberAuto)

            this.setState({saveChanges: {
                "title": "Номер неизвестного формата", 
                "text": `Вы уверены, что номер "${clearNumberAuto}" введен верно?`,
                "cancel": "Отмена",
                "save": "Сохранить",
                "function": {
                    "title": "invalid number format",
                    "numberAuto": result.numberAuto
                }
            }})
            return
        }

        this.orderPass(result)
    }

    render() {
        return(
            <div className="PassOrderPage">
                {sessionStorage.getItem('myUserEntity') === null ? (
                    <div>  </div>
                ) : (
                    <>
                        <SaveChanges 
                            show={this.state.saveChanges} 
                            setShow={() => this.setState({saveChanges: false})}
                            saveChange={(e) => this.saveChanges(e)}
                        />
                        <div id="ad"></div>
                        <div className="viewParentTop">
                            <div className="viewTop">
                                <div className="addressParking"> Москва, Дмитрия Ульянова 42с1 </div>
                                <div className="changeAccessListView">
                                    {JSON.parse(sessionStorage.getItem('myUserEntity'))["roleUser"] === "admin" ?
                                    <>
                                        <div className="TextFieldNumberAuto" style={{minWidth: '18%'}}> 
                                            <Form.Control placeholder="Введите номер авто *" className="number_auto_input"/> 
                                        </div>
                                        <div className="TextFieldCompany" style={{minWidth: '22%'}}> 
                                            <Form.Control placeholder="Введите компанию" className="company_input"/> 
                                        </div>
                                        <div className="TextFieldDescription" style={{minWidth: '30%'}}> 
                                            <Form.Control placeholder="Введите примечание *" className="description_input"/> 
                                        </div>
                                        <div className="TextFieldDateTimePicker" style={{minWidth: '20%'}}> 
                                            <div className="TextFieldDateTimePickerInner"> 
                                                < DateTimePicker valueRef={this.valueRef} defaultTime={this.state.defaultTime} maxTime={this.state.maxTime}/>
                                            </div>
                                        </div>
                                        <div className="ButtonChangeView" style={{minWidth: '10%'}}> 
                                            <button className="ButtonChange" onClick={() => this.order()}> Заказать </button>
                                        </div> 
                                    </> : 
                                    <>
                                        <div className="TextFieldNumberAuto" style={{minWidth: '30%'}}> 
                                            <Form.Control placeholder="Введите номер авто *" className="number_auto_input"/> 
                                        </div>
                                        <div className="TextFieldDescription" style={{minWidth: '35%'}}> 
                                            <Form.Control placeholder="Введите примечание *" className="description_input"/> 
                                        </div>
                                        <div className="TextFieldDateTimePicker" style={{minWidth: '20%'}}> 
                                            <div className="TextFieldDateTimePickerInner"> 
                                                < DateTimePicker valueRef={this.valueRef} defaultTime={this.state.defaultTime} maxTime={this.state.maxTime}/>
                                            </div>
                                        </div>
                                        <div className="ButtonChangeView" style={{minWidth: '15%'}}> 
                                            <button className="ButtonChange" onClick={() => this.order()}> Заказать </button>
                                        </div> 
                                    </>
                                    } 
                                </div>
                            </div>
                        </div>
                        { (Object.keys(this.state.data_table).length > 0) ? (
                        <div className="List">
                            <List 
                                data={this.state.data_table}
                                head={this.state.data_head}
                            />
                        </div>
                        ) : (
                            <div className="noData"> 
                                Нет данных
                             </div>  
                        )}
                    </>
                )}
            </div>
        )
    }

}